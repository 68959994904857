<template>
  <div class="main">
    <div class="main__head">
      <div
        class="title-1"
        v-text="$t('screen.productWidget.entry.title')"
      />

      <div class="main__login">
        <slot name="login" />
      </div>
    </div>

    <div
      v-if="persons.length > 0 || !$props.isPreview"
      class="main__tabs"
      :class="{ 'main__tabs--overflow': scrollerRef?.isOverflowing }"
    >
      <horizontal-scroller
        ref="scrollerRef"
        disable-controls
        class="container"
      >
        <template #items>
          <div
            v-for="person in $props.persons"
            :key="person.personId"
            class="tab"
            :class="{ 'tab--selected': person.personId === selectedPersonId }"
            @click="selectPerson(person.personId)"
          >
            <div class="tab__left"></div>
            <div class="tab__center">
              <person-name :person="person" />
            </div>
            <div class="tab__right"></div>
          </div>
        </template>
      </horizontal-scroller>

      <basic-context-menu
        v-model="tabsOpen"
        class="main__context-menu"
      >
        <template #trigger>
          <button
            v-if="scrollerRef?.isOverflowing"
            class="tabs__menu btn"
            @click="tabsOpen = !tabsOpen"
          >
            <the-icon
              art="solid"
              :name="tabsOpen ? 'chevron-up' : 'chevron-down'"
            />
          </button>
        </template>

        <template #content>
          <div
            v-for="person in $props.persons"
            :key="person.personId"
            class="link"
            :class="{ 'link--selected': selectedPersonId === person.personId }"
            @click="selectPerson(person.personId)"
          >
            <div class="link__text">
              <person-name
                class="body-2"
                :person="person"
              />
            </div>
            <div
              v-if="selectedPersonId === person.personId"
              class="link__icon"
            >
              <the-icon
                art="solid"
                name="check"
              />
            </div>
          </div>
        </template>
      </basic-context-menu>
    </div>

    <div class="main__products">
      <div
        ref="overflowRef"
        :style="varsForStyle"
        class="overflow"
      >
        <product-card
          v-for="product in productGroup"
          :key="product.productId"
          :is-narrow="actualProductsInViewport > 1"
          :is-preview="$props.isPreview"
          :person-id="selectedPersonId"
          :product="product"
          :source="$props.source"
          @open-form="$emit('open-form')"
        />
      </div>

      <basic-carousel-indicator
        v-if="productGroup.length > maxProductsInViewport"
        ref="controlRef"
        :quantity="Math.ceil(productGroup.length / maxProductsInViewport)"
        @select="selectProductIndex"
      />
    </div>

    <div class="main__actions">
      <div class="actions__grouped">
        <basic-text-button
          :label="$t('screen.productWidget.entry.editPerson')"
          @click="$emit('open-form')"
        >
          <template #trailing>
            <the-icon
              art="solid"
              name="plus"
            />
          </template>
        </basic-text-button>

        <basic-text-button
          v-if="$props.persons.length > 0 || !$props.isPreview"
          :label="$t('screen.priceWidget.widget.restart')"
          @click="$emit('restart')"
        >
          <template #trailing>
            <the-icon
              art="solid"
              name="arrow-rotate-left"
            />
          </template>
        </basic-text-button>
      </div>

      <basic-link
        v-if="$props.persons.length > 0"
        :label="$t('basket.gotoBasket')"
        @click="gotoBasket"
      >
        <template #trailing>
          <the-icon
            art="solid"
            name="arrow-right-long"
          />
        </template>
      </basic-link>

      <aide-banner :source="SOURCE.PRODUCT_WIDGET" />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { events$ } from '@/services'

import basketStore from '@/store/basket'
import productStructureStore from '@/store/productStructure'

import useApplication from '@/hooks/useApplication'
import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'
import usePersonDetails from '@/hooks/usePersonDetails'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'

import AideBanner from '@/components/Aide/Banner'
import BasicCarouselIndicator from '@/components/Basic/CarouselIndicator'
import BasicContextMenu from '@/components/Basic/ContextMenu'
import HorizontalScroller from '@/components/Container/HorizontalScroller'
import PersonName from '@/components/Person/PersonName'
import ProductCard from '@/modules/ProductWidget/components/ProductCard'
import { BasicLink, BasicTextButton } from '@/components/Basic'

import { CHANNEL } from '@/config/constants'
import { EVENT_TRACKING, SOURCE } from '@/config/events'

// HOOKS
const { getDvpUrl } = useApplication()
const { selectedLanguage } = useI18n()
const { getProduct } = usePersonSpecificProduct()
const { currentViewport, viewports } = useBrowser()
const { personsWithDetails } = usePersonDetails()

// INIT
const viewportLimits = [1, 2, 3, 3, 3, 3]
const MAX_CARD_WIDTH = 530
defineEmits(['open-form', 'restart'])
const props = defineProps({
  isPreview: {
    type: Boolean,
    default: false,
  },

  persons: {
    type: Array,
    required: true,
  },

  products: {
    type: String,
    required: true,
  },

  selectedProductId: {
    type: String,
    default: null,
  },

  source: {
    type: String,
    required: true,
  },
})

// DATA
const tabsOpen = ref(false)
const scrollerRef = ref(undefined)
const selectedPersonId = ref(null)
const selectedProductIndex = ref(0)
const cardWidth = ref()
const gapWidth = ref()
const overflowRef = ref(null)
const autoScroll = ref(false)
const controlRef = ref()
let initialWidth = window.innerWidth

// COMPUTED
const productGroup = computed(() => {
  return props.products.split(',').map(_productId => {
    const productId = _productId.trim()

    return {
      productId,
      ...productStructureStore.products.value[productId],
      ...getProduct(selectedPersonId.value, productId),
    }
  })
})

const productsInDisplay = computed(() => {
  let start = selectedProductIndex.value
  let end = selectedProductIndex.value + actualProductsInViewport.value
  const numberOfProducts = productGroup.value.length
  if (end > numberOfProducts) {
    start = end - numberOfProducts
    end -= end - numberOfProducts
  }
  return productGroup.value.slice(start, end)
})

const maxProductsInViewport = computed(() => {
  return viewportLimits[viewports.findIndex(v => v.name === currentViewport.value)]
})

const actualProductsInViewport = computed(() => {
  return Math.min(productGroup.value.length, maxProductsInViewport.value)
})

const varsForStyle = computed(() => {
  return [
    `--dvp-product-length: ${productGroup.value.length}`,
    `--dvp-products-in-view: ${actualProductsInViewport.value}`,
    `--card-width: ${cardWidth.value}`,
  ]
})

// METHOD
function gotoBasket() {
  let url = getDvpUrl(selectedLanguage.value) + '#/basket'
  if (personsWithDetails.value.length && basketStore.basket.channel === CHANNEL.PRODUCTWIDGET) {
    events$.emit(EVENT_TRACKING.ENTERED, { source: CHANNEL.PRODUCTWIDGET })
  }
  window.location.href = url
}

function handleResize(event) {
  if (initialWidth === event?.currentTarget?.innerWidth) return

  initialWidth = event?.currentTarget?.innerWidth

  gapWidth.value = parseFloat(
    getComputedStyle(document.body).getPropertyValue('--fixed-spacing-fix-03').replace('px', '')
  )

  const containerWidth = overflowRef.value.getBoundingClientRect().width

  if (productsInDisplay.value.length > 1) {
    cardWidth.value =
      Math.min(
        MAX_CARD_WIDTH,
        containerWidth / productsInDisplay.value.length -
          ((productsInDisplay.value.length - 1) * gapWidth.value) / productsInDisplay.value.length
      ) + 'px'
  } else {
    cardWidth.value = Math.min(MAX_CARD_WIDTH, containerWidth / productsInDisplay.value.length) + 'px'
  }

  selectProductIndex(0)
}

function handleFocusScrolling() {
  if (autoScroll.value) return

  const _cardWidth = parseFloat(cardWidth.value.replace('px', ''))
  controlRef.value.setIndex(Math.round(overflowRef.value.scrollLeft / _cardWidth))
}

async function selectPerson(_personId) {
  selectedPersonId.value = _personId

  if (scrollerRef.value) {
    await nextTick()

    scrollerRef.value.el.querySelector('.overflow .tab--selected').scrollIntoView({
      behaviour: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }
}

function selectProductIndex(index) {
  let scrollBy = 0

  // start
  if (index === 0) {
    scrollBy = 0

    // end
  } else if (index === productGroup.value.length - 1) {
    scrollBy = (productGroup.value.length + 1) * parseFloat(cardWidth.value.replace('px', ''))
  } else {
    // left/right
    scrollBy =
      actualProductsInViewport.value * index * parseFloat(cardWidth.value.replace('px', '')) +
      actualProductsInViewport.value * index * gapWidth.value
  }

  autoScroll.value = true

  overflowRef.value.scroll({
    left: scrollBy,
    behavior: 'smooth',
  })

  window.setTimeout(() => {
    autoScroll.value = false
  }, 501)
}

// WATCHER
watch(
  () => props.persons,
  async (newValue, oldValue) => {
    if (oldValue.length === 0 && newValue.length > 0) {
      selectPerson(newValue[0].personId)
    } else {
      // @note: if the selectedPersonId doesn't match any of the existing Persons anymore, reselect the first one
      if (!props.persons.find(p => p.personId === selectedPersonId.value)) {
        selectPerson(props.persons[0].personId)
      }
    }

    await nextTick()

    scrollerRef.value.recalc(scrollerRef.value.el, true)
  }
)

watch(
  () => overflowRef.value,
  value => {
    if (value) {
      handleResize()
      overflowRef.value.addEventListener('scroll', handleFocusScrolling)
    }
  }
)

// LIFECYCLE HOOKS
onBeforeMount(() => {
  // if not in preview mode and no selected person yet -> autoselect first person
  if (!props.isPreview && !selectedPersonId.value) {
    selectPerson(props.persons[0].personId)
  }
})

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
  overflowRef.value.addEventListener('scroll', handleFocusScrolling)
})
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  row-gap: var(--dotcom-responsive-gutter-responsive);
  width: 100%;

  .main__tabs {
    position: relative;

    background-color: var(--surface-warm);
    border-radius: var(--radius-border-radius-04);
    padding: 4px 0 0 20px;

    /* configures the horizontal-scroller component */
    :deep(.overflow) {
      gap: var(--fixed-spacing-fix-04);
      width: 100%;
    }

    &--overflow :deep(.overflow) {
      width: calc(100% - 44px);
    }

    .tab {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 44px;
      cursor: pointer;

      .tab__left {
        position: relative;
        width: 20px;

        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;

          background-color: var(--surface-warm);
        }

        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: var(--surface-warm);
          border-radius: 0 0 25px 0;
        }
      }

      .tab__center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--surface-warm);

        padding: 10px 40px;
        border-radius: 25px 25px 0 0;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: var(--font-size-regular-md);
        letter-spacing: var(--letter-spacing-regular-md);
      }

      .tab__right {
        position: relative;
        width: 20px;

        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;

          background-color: var(--surface-warm);
        }

        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: var(--surface-warm);
          border-radius: 0 0 0 25px;
        }
      }

      &:hover {
        .tab__left:before,
        .tab__center,
        .tab__right:before {
          background-color: var(--surface-ext-b-medium);
        }
      }

      &:active {
        .tab__left:before,
        .tab__center,
        .tab__right:before {
          background-color: var(--surface-ext-b-high);
        }
      }

      &--selected {
        .tab__center,
        .tab__center :deep(.person-name) {
          font-weight: var(--font-weight-bold);
        }

        .tab__left:before,
        .tab__center,
        .tab__right:before {
          background-color: var(--surface);
        }
      }
    }

    .tabs__menu {
      height: 48px;

      border: none;
      background: var(--surface-warm);
      border-radius: var(--fixed-border-radius-fix-full);
      z-index: var(--dvp-stack-level-element);
      width: 48px;
      cursor: pointer;
      color: var(--Interaction-States-enabled-extended);

      box-shadow: -8px 2px 8px -4px rgba(0, 0, 0, 0.2);

      &:hover {
        background: var(--surface-ext-b-medium);
      }

      &:focus {
        background: var(--surface-ext-b-medium);
      }

      &:active {
        background: var(--surface-ext-b-high);
      }
    }
  }

  .overflow {
    overflow-y: hidden;
    overflow-x: hidden;

    display: grid;
    grid-template-columns: repeat(var(--dvp-product-length), var(--card-width));
    grid-template-rows: repeat(6, minmax(0, min-content));
    gap: 0 var(--fixed-spacing-fix-03);
  }

  .link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: var(--fixed-spacing-fix-04);

    text-decoration: underline;
    text-decoration-color: transparent;

    cursor: pointer;

    transition-property: background-color, font-weight, text-decoration-color;
    transition-duration: 0.5s;
    transition-timing-function: linear;

    padding: var(--fixed-spacing-fix-02) var(--fixed-spacing-fix-04);

    &:first-child {
      border-radius: var(--fixed-border-radius-fix-02) var(--fixed-border-radius-fix-02) 0 0;
      padding: var(--fixed-spacing-fix-03) var(--fixed-spacing-fix-04) var(--fixed-spacing-fix-02);
    }

    &:last-child {
      border-radius: 0 0 var(--fixed-border-radius-fix-02) var(--fixed-border-radius-fix-02);
      padding: var(--fixed-spacing-fix-02) var(--fixed-spacing-fix-04) var(--fixed-spacing-fix-03);
    }

    .link__text {
      flex: 0 1 auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      white-space: nowrap;
    }

    .link__icon {
      flex: 0 0 auto;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .icon {
        color: var(--on-surface-medium);
      }
    }

    &:hover,
    &.link--selected:hover {
      background: var(--Interaction-States-Dropdowns-hovered);
      text-decoration-color: var(--on-surface);
    }

    &:focus,
    &.link--selected:focus {
      background: var(--Interaction-States-Dropdowns-focused);
      text-decoration-color: var(--on-surface);
    }

    &:active,
    &.link--selected:active {
      background: var(--Interaction-States-Dropdowns-pressed);
      text-decoration-color: var(--on-surface);
    }

    &.link--selected {
      background-color: var(--Interaction-States-Dropdowns-Selected);
    }
  }
}

.main__context-menu {
  position: absolute;
  right: 0;
  top: 0;
}

.main__context-menu:deep(.menu) {
  min-width: 280px;
}

.main__actions,
.actions__grouped,
.main__head {
  display: flex;
  flex-direction: column;
  row-gap: var(--fixed-spacing-fix-04);
}

@media (--v-medium) {
  .main__actions {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--fixed-spacing-fix-10);

    justify-content: space-between;
  }

  .actions__grouped {
    flex-direction: row;
    column-gap: var(--fixed-spacing-fix-10);
  }
}

@media (--v-large) {
  .main {
    .main__tabs {
      .tab,
      .tabs__menu {
        height: 48px;
      }

      .tabs__menu {
        width: 48px;
      }
    }
  }

  .main__head {
    flex-direction: row;
    column-gap: var(--fixed-spacing-fix-10);

    justify-content: space-between;
  }
}

@media (--v-wide) {
}

@media (--v-ultra) {
  .main__tabs {
    .tab,
    .tabs__menu {
      height: 56px;
    }

    .tabs__menu {
      width: 56px;
    }
  }
}
</style>
